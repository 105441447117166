import { graphql } from "gatsby";
import React from "react";
import Helmet from "react-helmet";
import PostListing from "../components/PostListing/PostListing";
import config from "../../data/SiteConfig";
import Layout from "../components/layout";


export default function CategoryTemplate(props) {
  const {
    location,
    pageContext: { category },
    data: { allMarkdownRemark, authors }
  } = props;

  const postEdges = allMarkdownRemark.edges;
  // const authorsEdges = authors.edges;
  return (
      <Layout location={location}>
        <div className="category-container">
          <Helmet
              title={`Posts in category "${category}" | ${config.siteTitle}`}
          />
          <PostListing postEdges={postEdges} />
        </div>
      </Layout>
  );
}

// class CategoryTemplate extends React.Component {
//   render() {
//     const {
//       location,
//       pageContext: { category },
//       data: { allMarkdownRemark, authors }
//     } = this.props;
//
//     const postEdges = allMarkdownRemark.edges;
//     const authorsEdges = authors.edges;
//     return (
//       <Layout location={location}>
//         <div className="category-container">
//           <Helmet
//             title={`Posts in category "${category}" | ${config.siteTitle}`}
//           />
//           <PostListing postEdges={postEdges} postAuthors={authorsEdges} />
//         </div>
//       </Layout>
//     );
//   }
// }

/* eslint no-undef: "off" */
export const pageQuery = graphql`
  query CategoryPage($category: String) {
    allMarkdownRemark(
      limit: 1000
      sort: { fields: [frontmatter___date], order: DESC }
      filter: { frontmatter: { category: { eq: $category } } }
    ) {
      totalCount
      edges {
        node {
          fields {
            slug
          }
          excerpt
          timeToRead
          frontmatter {
            title
            tags
            cover
            date
          }
        }
      }
    }
  }
`;

// export default CategoryTemplate;
